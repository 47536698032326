import React from "react";
import {graphql} from "gatsby";
import {mapEdgesToNodes, filterOutDocsWithoutSlugs, filterOutDocsPublishedInTheFuture} from "../lib/helpers";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Logo from "../branding/magnafire-logo.gif";
import {motion} from "framer-motion";

import Hero from "../components/hero";
import MailchimpModal from "../components/modal-newsletter";

// Add  limit: 12 above project sort

export const query = graphql `
query IndexPageQuery {
  site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
    title
    description
    keywords
    socialShareImage {
      ...Image
    }
  }
  projects: allSanityWork(
    sort: {fields: [publishedAt], order: DESC}
    filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
  ) {
    edges {
      node {
        id
        mainImage {
          ...Image
        }
        title
        client {
          clientName
        }
        slug {
          current
        }
      }
    }
  }
}
`;

const whiteBox = {
    initial: {
        scale: 1,
        top: 0,
        opacity: 1,
        zIndex: 9999
    },
    animate: {
        scale: 0,
        opacity: 0,
        transition: {
            when: "afterChildren",
            scale: {
                delay: 3.90,
                duration: .1
            },
            opacity: {
                delay: 2.75,
                duration: 1.25,
                ease: [0.85, 0, 0.13, 1]
            }
        }
    }
};

const logoContainer = {
    initial: {
        opacity: 1
    },
    animate: {
        opacity: 0,
        transition: {
            delay: 3,
            duration: .5,
            when: "afterChildren"
        }
    }
};

const IndexPage = props => {
    const {data} = props;

    const logoGIF = Logo + "?a=" + Math.random();

    const site = (data || {}).site;
    const projectNodes = (data || {}).projects
        ? mapEdgesToNodes(data.projects)
            .filter(filterOutDocsWithoutSlugs)
            .filter(filterOutDocsPublishedInTheFuture)
        : [];

    return (
        <Layout>
            <motion.div
                className="fixed flex items-center justify-center w-full h-full bg-white"
                initial="initial"
                animate="animate"
                variants={whiteBox}
                onAnimationStart={() => document.body.classList.add("overflow-hidden")}
                onAnimationComplete={() => document.body.classList.remove("overflow-hidden")}>
                <motion.div initial="initial" animate="animate" variants={logoContainer}>
                    <img src={logoGIF} alt="Magnafire Media Logo" className="h-20"/>
                </motion.div>
            </motion.div>
            <SEO
                title={site.title}
                description={site.description}
                keywords={site.keywords}
                image={site.socialShareImage}/>
            <Hero/> {projectNodes && (<ProjectPreviewGrid nodes={projectNodes} browseMoreHref="/work/"/>)}
            <MailchimpModal/>
        </Layout>
    );
};

export default IndexPage;
