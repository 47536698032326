import React, {useState, useEffect, Fragment} from "react";
import {useSessionStorage} from "../lib/useSessionStorage";
import {useStaticQuery, graphql} from "gatsby";
import {Dialog, Transition} from "@headlessui/react";
import MailchimpPopUp from "./mailchimp-popup";
import {MdOutlineClose} from "react-icons/md";

function MailchimpModal() {

    const data = useStaticQuery(graphql `
    query ModalNewsletter {
        sanityHomePage {
          modalToggle
          mailchimpPopupHeader
          mailchimpPopupBody
          mailchimpPopupButton
          mailchimpPopupFooter
        }
      }               
  `);

    const content = data.sanityHomePage;
    const modalToggled = content.modalToggle;

    const [viewed,
        setViewed] = useSessionStorage('viewed', false);

    const [isOpen,
        setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    useEffect(() => {
        if (modalToggled === true && !viewed) {
            const timeout = setTimeout(() => {
                setIsOpen(true);
                setViewed(true);
            }, 5000);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, []);

    useEffect(() => {
        if (isOpen) {
            const timeout = setTimeout(() => {
                document
                    .documentElement
                    .style
                    .removeProperty("overflow");
                document
                    .documentElement
                    .style
                    .removeProperty("padding");
            }, 0)
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [isOpen]);

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => {}}>
                <div className="fixed inset-0 overflow-y-auto pointer-events-none">
                    <div
                        className="flex min-h-full items-center justify-center lg:items-end lg:justify-end p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel
                                className="w-full max-w-md transform bg-white p-6 text-left align-middle shadow-xl transition-all pointer-events-auto">
                                <div className="absolute -right-2 -top-2">
                                    <MdOutlineClose
                                        className="text-3xl p-1 text-white bg-black rounded-full cursor-pointer"
                                        onClick={closeModal}/>
                                </div>
                                <Dialog.Title
                                    as="h3"
                                    className="text-2xl font-bold leading-6 text-gray-900 text-center uppercase">
                                    {content.mailchimpPopupHeader}
                                </Dialog.Title>
                                {content.mailchimpPopupBody && <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        {content.mailchimpPopupBody}
                                    </p>
                                </div>}
                                <MailchimpPopUp buttonText={content.mailchimpPopupButton}/> 
                                {content.mailchimpPopupFooter && <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        {content.mailchimpPopupFooter}
                                    </p>
                                </div>}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default MailchimpModal;