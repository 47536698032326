import React from "react";
import {useStaticQuery, graphql} from "gatsby";
import VideoLoop from "./vimeo-loop";
import MediaQuery from 'react-responsive';

const Hero = () => {

    const data = useStaticQuery(graphql `
    query heroVideo {
        sanityHomePage {
          desktopBackgroundVideo
          mobileBackgroundVideo
        }
      }
  `);

    const desktop = data.sanityHomePage.desktopBackgroundVideo;
    const mobile = data.sanityHomePage.mobileBackgroundVideo;

    return (
        <section id="hero-video" className="hero relative w-full">
            <MediaQuery maxWidth={480} className="hero--bg mobile">
                <VideoLoop id={mobile}/>
            </MediaQuery>
            <MediaQuery minWidth={481} className="hero--bg desktop">
                <VideoLoop id={desktop}/>
            </MediaQuery>
        </section>
    )
}

export default Hero;
