import React from "react";
import Mailchimp from "react-mailchimp-form";

function MailchimpPopUp(props){

    return (
        <Mailchimp
        action='https://magnafiremedia.us17.list-manage.com/subscribe/post?u=394cc178d4bdaf259079d045e&amp;id=6e227a681a'
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Email',
            type: 'email',
            required: true
          },
          {
            name: 'FNAME',
            placeholder: 'First Name',
            type: 'text',
            required: true
          },
          {
            name: 'LNAME',
            placeholder: 'Last Name',
            type: 'text',
            required: true
          },
        ]}
        messages = {
          {
            sending: "Sending...",
            success: "Thanks for subscribing!",
            error: "An unexpected internal error has occurred.",
            empty: "You must fill out the required fields.",
            duplicate: "Too many subscribe attempts for this email address.",
            button: JSON.stringify(props.buttonText)
          }
        }
        className='mailchimp-popup'
        />
    );
}

export default MailchimpPopUp;