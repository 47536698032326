import React from "react";

const VideoLoop = ({
  id,
  width = 16,
  height = 9,
  initialState = false,
  ...rest
}) => {
  if (!id) return null

  return (
    <div className="video-loop" {...rest}>
      <iframe
        src={`https://player.vimeo.com/video/${id}?background=1&autoplay=1&mute=1&autopause=0&loop=1`}
        frameBorder="0"
        allow="autoplay; fullscreen"
        style={{
          height: `${(height / width) * 100}vw`,
          minWidth: `${(width / height) * 100}vh`,
        }}
        className="vimeo-iframe"
      ></iframe>
    </div>
  )
}

export default VideoLoop